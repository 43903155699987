<template>
  <v-container>
    <v-row justify="space-between">
      <v-col
        cols="12"
        class="py-5 px-8"
      >
        <v-row>
          <v-text-field
            label="Search Packages"
            append-icon="mdi-magnify"
            @change="search"
          />
        </v-row>
      </v-col>
    </v-row>

    <v-card>
      <v-data-table
        :headers="headers"
        :items="coupons.data"
        :options.sync="tableOptions"
        :loading="loading"
        class="elevation-0"
        :footer-props="{
          'items-per-page-options': tableOptions.rowsPerPageItems
        }"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          {{
            getReadableDate(item.createdAt)
          }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <template v-for="(act, index) in coupons.actions">
            <v-tooltip
              :key="index"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="act === 'edit' ? editItem(item) : ''"
                >
                  mdi-{{
                    act === 'view' ? 'eye' : act === 'block' ? 'account-cancel' : act === 'edit' ? 'pencil' : ''
                  }}
                </v-icon>
              </template>
              <span>{{ act }}</span>
            </v-tooltip>
            <v-tooltip
              :key="index"
              bottom
            >
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="deletePackage(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            <span>Delete</span>
            </v-tooltip>
          </template>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
  import { getPackages, deletePackage } from '@/services/admin'
  export default {
    data () {
      return {
        userFormOpen: false,
        usersStatus: 'all',
        headers: [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          {
            text: 'Status',
            align: 'start',
            sortable: false,
            value: 'status',
          },
          {
            text: 'Price',
            align: 'start',
            sortable: false,
            value: 'price',
          },
          {
            text: 'Value',
            align: 'start',
            sortable: false,
            value: 'value',
          },
          {
            text: 'Description',
            align: 'start',
            sortable: false,
            value: 'description',
          },
          {
            text: 'Action',
            align: 'start',
            sortable: false,
            value: 'actions',
          },
        ],
        coupons: {
          data: [],
          actions: ['edit'],
        },
        tableOptions: {
          page: 1,
          itemsPerPage: 10,
          totalItems: 0,
          rowsPerPageItems: [10, 50, 100, 200],
        },
        loading: true,
        searchKeyword: '',
      }
    },
    watch: {
      tableOptions: {
        handler () {
          this.loading = true
          const _self = this
          setTimeout(function () {
            _self.getData()
          }, 500)
        },
        deep: true,
      },
    },
    async mounted () {
      this.getData()
    },
    methods: {
      search (keyword) {
        this.searchKeyword = keyword
        this.getData()
      },
      userModalClose () {
        this.userFormOpen = false
        this.getData()
      },
      async getData () {
        const params = null // {
        //   status: this.usersStatus === 'all' ? null : this.usersStatus,
        //   search: this.searchKeyword,
        //   limit: this.tableOptions.itemsPerPage,
        //   page: this.tableOptions.page,
        //   type: 'customer',
        // }
        getPackages(params).then(res => {
          this.coupons.data = res.data.message
          this.loading = false
        })
      },
      getReadableDate (date) {
        const d = new Date(date)
        return d.toUTCString()
      },
      editItem (item) {
        this.$emit('editItem', JSON.stringify(item))
      },
      deletePackage (item) {
        if (confirm('Are your Sure?')) {
          deletePackage(item.id).then(res => {
            if (res.status === 204) return alert('User Delete Successfully!')
            else return alert('Try Again! Some went wrong.')
          })
          window.location.reload()
        }
      },
    },
  }
</script>
