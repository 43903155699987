import { render, staticRenderFns } from "./giftcard.vue?vue&type=template&id=47f13fbc"
import script from "./giftcard.vue?vue&type=script&lang=js"
export * from "./giftcard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports