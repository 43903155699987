<template>
  <v-container
    id="coupons-list"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="4"
      >
        <base-material-card>
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Add New
            </div>

            <div class="text-text-subtitle-1 font-weight-light">
              Add new package item
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="packageObj.name"
                    class="purple-input"
                    label="Package Name"
                  />
                </v-col>
                <v-col
                  cols="6"
                  md="6"
                >
                  <v-select
                    v-model="packageObj.status"
                    :items="packageTypeOptions"
                    item-text="label"
                    item-value="value"
                    label="Package Status"
                  />
                </v-col>
                <v-col
                  cols="6"
                  md="6"
                >
                  <v-text-field
                    v-model="packageObj.price"
                    class="purple-input"
                    type="number"
                    label="Package Price"
                  />
                </v-col>
                <v-col
                  cols="10"
                  md="10"
                >
                  <v-text-field
                    v-model="packageObj.value"
                    class="purple-input"
                    type="number"
                    label="Package Value"
                  />
                </v-col>
                <v-col
                   cols="12"
                    sm="12"
                    md="12"
                >
                 <v-textarea
                    v-model="packageObj.description"
                    clearable
                    clear-icon="mdi-close-circle"
                    label="Description"
                    :value="packageObj.description"
                  ></v-textarea>
                          </v-col>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="success"
                    class="mr-0"
                    @click="isEdit ? updatePackageAction() : createPackageAction()"
                  >
                    {{ isEdit ? 'Update' : 'Create' }} Package
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-alert
                    v-if="alert.show"
                    border="left"
                    dense
                    text
                    :type="alert.type"
                  >
                    {{ alert.text }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>

      <v-col
        cols="8"
      >
        <base-material-card color="info">
          <template v-slot:heading>
            <div class="text-h3 font-weight-light">
              Available Packages
            </div>

            <div class="text-text-subtitle-1 font-weight-light">
              (in List)
            </div>
          </template>
          <v-card
            class="mx-auto"
            outlined
          >
            <package-list
              :key="listKey"
              @editItem="editItem"
            />
          </v-card>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { createPackage, updatePackage } from '@/services/admin'
  import packageList from '@/components/packageList'
  export default {
    components: { packageList },
    data () {
      return {
        packageObj: {

        },
        date: new Date().toISOString().substr(0, 10),
        dateModal: false,
        packageTypeOptions: [{ value: 'Active', label: 'Active' }, { value: 'InActive', label: 'InActive' }],
        alert: {
          show: false,
          text: '',
          type: 'success',
        },
        isEdit: false,
        itemId: null,
        listKey: 'abc',
      }
    },
    computed: {

    },
    methods: {
      createPackageAction () {
        if (!this.packageObj.name || !this.packageObj.status || !this.packageObj.price || !this.packageObj.value || !this.packageObj.description) {
          this.alert.show = true
          this.alert.text = 'Please fill all required fields.'
          this.alert.type = 'error'
          return
        }
        createPackage(this.packageObj).then(res => {
          this.alert.show = true
          this.alert.text = 'Package Created.'
          this.alert.type = 'success'
          this.packageObj = {}
        })
      },
      updatePackageAction () {
        if (!this.packageObj.name) {
          this.alert.show = true
          this.alert.text = 'Please fill all required fields.'
          this.alert.type = 'error'
          return
        }
        updatePackage(this.itemId, this.packageObj).then(res => {
          this.alert.show = true
          this.alert.text = 'Package Updated.'
          this.alert.type = 'success'
          this.isEdit = false
          this.packageObj = {}
          this.listKey = new Date()
        })
      },
      editItem (items) {
        const data = JSON.parse(items)
        this.itemId = data.id
        delete data.id
        delete data.createdAt
        this.packageObj = data
        this.isEdit = true
        this.alert.show = false
      },
      getReadableDate (date) {
        const d = new Date(date)
        return d.toUTCString()
      },
    },
  }
</script>
